<template>
	<template-base>
		<h1>Página não encontrada</h1>
		<section>
			<AlertOctagonIcon class="text-dark" size="10x" />
		</section>
		<div class="text-right mt-4">
			<router-link
				:to="{ name: 'home' }"
				class="btn btn-success text-white lg"
				>Ir para a página inicial</router-link
			>
		</div>
	</template-base>
</template>

<style scoped>
	section {
		height: 30vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>

<script>
	import TemplateBase from "../templates/Base";

	export default {
		components: {
			TemplateBase
		}
	};
</script>
